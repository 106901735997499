<template>
  <div
    :class="{ mobile: device === 'mobile' }"
    class="navbar"
  >
    <div class="logo">
      <div class="logo-image">
        <img
          class="image"
          src="@/assets/logo.png"
        >
      </div>
      <div class="logo-title">
        <div class="title">株式会社瑞創</div>
        <div class="eng">mizusou Co., Ltd.</div>
      </div>
    </div>
    <div
      v-if="device !== 'mobile'"
      class="right"
    >
      <div class="lang">
        <select
          class="select"
          @change="handleSetLanguage($event)"
        >
          <option value="zh">中国语</option>
          <option value="ja">日本语</option>
        </select>
      </div>
      <div class="menu">
        <div class="menu-space" />
        <div
          v-for="(item, index) of link"
          :key="index"
          class="menu-item"
          :class="{ 'menu-select': item.name === key }"
          @click="handleRouter(item)"
        >
          <span>{{ $t('navbar.' + item.name) }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="device === 'mobile'"
      class="right"
    >
      <el-button
        type="text"
        class="drawer-button"
        @click="dialog = true"
      />
      <el-drawer
        size="70%"
        :visible.sync="dialog"
      >
        <div class="drawer">
          <div class="drawer-menu">
            <div
              v-for="(item, index) of link"
              :key="index"
              class="menu-item"
              :class="{ 'menu-select': item.name === key }"
              @click="handleRouter(item)"
            >
              <span>{{ $t('navbar.' + item.name) }}</span>
            </div>
          </div>
          <div class="lang">
            <select
              class="select"
              @change="handleSetLanguage($event)"
            >
              <option value="zh">中国语</option>
              <option value="ja">日本语</option>
            </select>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import ResizeMixin from '@/mixin/ResizeHandler'
import { mapState } from 'vuex'
export default {
  name: 'Navbar',
  mixins: [ResizeMixin],
  data() {
    return {
      link: [
        {
          name: 'home-page',
          path: '/home-page'
        },
        {
          name: 'company-profile',
          path: '/company'
        },
        {
          name: 'product',
          path: '/product'
        }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device
    }),
    key() {
      return this.$route.meta.tag
    }
  },
  methods: {
    handleSetLanguage(event) {
      this.$i18n.locale = event.target.value
    },
    handleRouter(item) {
      if (item.name === this.key) {
        return
      }
      this.$router.push(item.path)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  width: 1000px;
  margin: auto;
  height: 63px;
  padding: 16px 0;
  display: flex;
  .logo {
    width: 300px;
    display: flex;
    padding: 0 0 0 80px;
    .logo-image {
      .image {
        height: 61px;
        width: 95px;
      }
    }
    .logo-title {
      padding: 7px 10px;
      .title {
        height: 21px;
        font-size: 22px;
        font-family: Hiragino Sans GB;
        font-weight: normal;
        color: #010101;
      }
      .eng {
        padding: 10px 0;
        height: 10px;
        font-size: 14px;
        font-family: YouSheBiaoTiHei;
        font-style: italic;
        font-weight: 800;
        color: #676767;
      }
    }
  }
  .right {
    width: calc(100% - 300px);
    padding: 0 80px 0 0;
    text-align: right;
    .lang {
      width: 100%;
      height: 24px;
      .select {
        width: 85px;
        height: 30px;
        background: #f1000f;
        color: #fff;
        border: none;
        outline: none;
        text-align: center;
      }
    }
    .menu {
      width: 100%;
      display: flex;
      padding: 16px 0;
      .menu-space {
        flex: 1;
      }
      .menu-item {
        width: 140px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .menu-select {
        span {
          display: inline-block;
          border-bottom: 3px solid red;
        }
      }
    }
  }
}

.mobile {
  width: 100%;
  .logo {
    padding: 0 0 0 5px;
  }
  .right {
    padding: 10px;
    .drawer-button {
      width: 40px;
      height: 40px;
      background: #5c1612;
      background-image: url('~@/assets/menu.png');
      background-size: cover;
    }
    .drawer {
      display: flex;
      flex-direction: column;
      height: 100%;
      .drawer-menu {
        flex: 1;
        text-align: left;
        .menu-item {
          height: 30px;
          line-height: 30px;
          width: calc(100% - 40px);
          font-size: 20px;
          padding: 20px;
        }
        .menu-select{
          color: #949292;
        }
      }
      .lang {
        text-align: left;
          width: calc(100% - 40px);
        padding: 0 20px;
        height: 50px;
      }
    }
  }
}
</style>

<style lang="scss">
.el-drawer__open .el-drawer.rtl {
  border-radius: 20px 0 0 0;
}
</style>
