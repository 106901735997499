<template>
  <div
    :class="{mobile: device === 'mobile'}"
    class="app-wrapper"
  >
    <div>
      <navbar />
    </div>
    <app-main />
  </div>
</template>

<script>
import { AppMain, Navbar } from '@/layout/components'

import ResizeMixin from '@/mixin/ResizeHandler'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar
  },
  mixins: [ResizeMixin],
  data() {
    return {
      fixedHeader: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.app.device
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
