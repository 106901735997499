<template>
  <section
    class="app-main"
    :class="{mobile: device === 'mobile'}"
  >
    <transition
      name="fade-transform"
      mode="out-in"
    >
      <el-scrollbar style="height:100%">
        <div class="app-view">
          <keep-alive>
            <router-view :key="key" />
          </keep-alive>
        </div>
        <app-footer />
      </el-scrollbar>
    </transition>
    <el-backtop target=".el-scrollbar__wrap" />
  </section>
</template>

<script>
import ResizeMixin from '@/mixin/ResizeHandler'
import { mapState } from 'vuex'
import AppFooter from './AppFooter'
export default {
  name: 'AppMain',
  components: {
    AppFooter
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      device: state => state.app.device
    }),
    key() {
      return this.$route.path
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 95= navbar  95  */
  height: calc(100vh - 95px);
  width: 100%;
  position: relative;
  overflow: hidden;
  .app-view{
    width: 1000px;
    margin: auto;
  }
}

.mobile{
  .app-view{
    width: 100%;
    margin: auto;
  }
}

</style>
