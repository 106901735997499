<template>
  <div
    class="footer"
    :class="{mobile: device === 'mobile'}"
  >
    <div class="contact">
      <img src="~@/assets/footer/facebook.png">
      <img src="~@/assets/footer/twitter.png">
      <img src="~@/assets/footer/line.png">
      <img src="~@/assets/footer/youtube.png">
    </div>
    <div class="copy-right">Copyright© MIZUSOU All rights reserved</div>
  </div>
</template>

<script>
import ResizeMixin from '@/mixin/ResizeHandler'
import { mapState } from 'vuex'
export default {
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      device: state => state.app.device
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.footer {
  width: 1000px;
  margin: auto;
  height: 191px;
  display: flex;
  background: url('~@/assets/footer/footer.png');
  background-size: cover;
  .contact {
    flex: 1;
    padding:73px 80px;
    img {
      margin: 9px;
      width: 27px;
      height: 26px;
    }
  }
  .copy-right {
    flex: 1;
    text-align: right;
    width: 557px;
    height: 27px;
    line-height: 27px;
    font-size: 14px;
    font-weight: 200;
    color: #ffffff;
    margin: 82px 80px;
  }
}

.mobile{
  width: 100%;
  flex-direction: column;
  .contact {
    display: flex;
    justify-content:center;
    align-items:  flex-end;
    padding: 0 ;
    img {
      margin: 9px;
      width: 27px;
      height: 26px;
    }
  }
  .copy-right {
    text-align: center;
    width: 100%;
    height: 27px;
    line-height: 27px;
    font-size: 14px;
    font-weight: 200;
    color: #ffffff;
    margin: 0px 0px;
  }
}
</style>
