import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home-page',
    children: [
      {
        path: 'home-page',
        component: () => import('@/views/home-page'),
        meta: { tag: 'home-page' },
        name: 'home-page'
      },
      {
        path: 'product',
        component: () => import('@/views/product'),
        meta: { tag: 'product' },
        name: 'product'
      },
      {
        path: 'product-detail',
        component: () => import('@/views/product-detail'),
        meta: { tag: 'product-detail' },
        name: 'product-detail'
      }
    ]
  },
  {
    path: '/company',
    redirect: '/company/company-profile',
    component: Layout,
    children: [
      {
        path: 'company-profile',
        component: () => import('@/views/company/company-profile'),
        meta: { tag: 'company-profile' },
        name: 'company-profile'
      },
      {
        path: 'company-business',
        component: () => import('@/views/company/company-business'),
        meta: { tag: 'company-business' },
        name: 'company-business'
      },
      {
        path: 'company-philosophy',
        component: () => import('@/views/company/company-philosophy'),
        meta: { tag: 'company-philosophy' },
        name: 'company-philosophy'
      }
    ]
  }
]

const createRouter = () => {
  const router = new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })
  return router
}

const router = createRouter()

export default router
