import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// 引入样式
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper /* { 全局组件的默认选项 } */)

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.use(ElementUI)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
